import consumer from "./consumer"

consumer.subscriptions.create("ImportFileAutoRefreshChannel", {
    received(data) {
        var organizationId = pathArray('organizations', 1);

        if (data['message'] === 'success' && pathArray('organizations', 2) === 'import_files') {
            var importFileId = data['id'].toString();
            toastr['success']('File Imported Successfully');
            playAlert('glass');
            if (pathArray('import_files', 1) === importFileId) {
                location.reload(true);
            }
            if (data['message'] === 'success' && data['partial'].length > 0) {
                $("[data-id=import-file-row-" + data['id'] + "]").replaceWith(data['partial']);
            }
        }
    }

});

function pathArray(name = '', index = 0) {
    if (name.length === 0) {
        return window.location.pathname.split('/');
    } else {
        var tempArr = pathArray();
        return tempArr[tempArr.indexOf(name) + index];
    }
}

