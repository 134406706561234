// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("stupid-table-plugin/stupidtable");
require("components");
require("channels");
require("jquery");
require("app");

global.Pikaday = require('pikaday/pikaday');

var custom_options, loadDatePicker;
window.loadAllDatePickerElements = function () {
    var datePickers;
    datePickers = $('[data-toggle="datepicker"]');
    return datePickers.each(function () {
        var item;
        item = $(this);
        return loadDatePicker(item);
    });
};

loadDatePicker = function (item) {
    new Pikaday({
        field: item[0],
        format: 'DD MMM YYYY',
        minDate: new Date(item.data('startDate')),
        maxDate: new Date(item.data('endDate'))
    });
};


$(document).on('turbolinks:load', function () {
    loadAllDatePickerElements();
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
