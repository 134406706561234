import toastr from 'toastr/toastr';
import playAlert from './alert';

window.toastr = toastr;
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "2500",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

$(document).on('turbolinks:load', function () {
    let $sort_tables = $('[data-behaviour=sort_table]');
    $sort_tables.stupidtable();

    $(document).on('keydown keypress keyup change', '[data-behaviour="search-table"]', function () {
        var $searchTargets, $this, searchParam;
        $this = $(this);
        searchParam = $this.val();
        $searchTargets = $('[data-search-target]');

        if (searchParam === '') {
            return $searchTargets.show();
        } else {
            $searchTargets.hide();
            return $searchTargets.each(function () {
                var $target;
                $target = $(this);
                if ($target.attr('data-search-target').toLowerCase().search(searchParam.toLowerCase()) >= 0) {
                    return $target.show();
                }
            });
        }
    });

    $(document).on('change', 'input[type="file"]', function (ev) {
        let $cf = $(this).parents('div.file');
        if ($cf.find('span.file-name').length > 0) {
            $cf.find('span.file-name').text(ev.target.files[0].name);
        } else {
            $cf.find('span.file-label').text(ev.target.files[0].name);
        }
        let $fileNameField = $(this).parents('form').find("input[name=import_file\\[file_name\\]]");
        if ($fileNameField.length > 0) {
            $fileNameField.val(ev.target.files[0].name)
        }
    });

    $(document).on('click', '[data-toggle=modal]', function(e) {
        var $target, $this, templateId;
        e.preventDefault();
        $this = $(this);
        $target = $($this.data('target'));
        templateId = $this.data('loadTemplate');
        if (templateId !== void 0) {
            $target.find('.modal-card').html($(templateId).html());
        }
        $($target).addClass('is-active');
        return $($target).find('.input').attr('autofocus', 'autofocus');
    });

    $(document).on('click', '[data-dismiss=modal], .modal-background', function(e) {
        var $this;
        e.preventDefault();
        $this = $(this);
        return $this.parents('.modal').removeClass('is-active');
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            return $('.modal').removeClass('is-active');
        }
    });

});


