$(document).on('turbolinks:load', function () {
    var $checkBoxLink;
    $checkBoxLink = $('[data-behaviour="checkbox-link-btn"]');

    $(document).on('change', '[data-behaviour="gst-validation-checkboxes"]', function () {
        return checkBoxLinkBtnPropChange($checkBoxLink);
    });

    return $checkBoxLink.click(function (event) {
        var ids, item, name, url;
        item = $(this);
        if (item.attr('disabled')) {
            return false;
        } else {
            event.preventDefault();
            if (getGstIdFromCheckedBoxes().length > 0) {
                url = new URL(event.target.href);
                ids = getGstIdFromCheckedBoxes();
                name = item.data('name');
                url.searchParams.append(name, ids);
                return item.attr('href', url.href);
            } else {
                toastr['info']('Select some items');
                event.preventDefault();
                return false;
            }
        }
    });
});

$(document).on('click', '[data-behaviour="select-checkbox-row"]', function (event) {
    var checkBox, $checkBoxLink;
    var $cell = $(event.target).closest('td');
    if ($cell.index() > 0) {
        checkBox = $($(this).find('td>input[type="checkbox"]')[0]);
        $checkBoxLink = $('[data-behaviour="checkbox-link-btn"]');
        if (checkBox.is(':checked')) {
            checkBox.prop("checked", false);
            return checkBoxLinkBtnPropChange($checkBoxLink);
        } else {
            checkBox.prop("checked", true);
            return checkBoxLinkBtnPropChange($checkBoxLink);
        }
    }
});

$(document).on('click', '[data-behaviour="select-all-checkboxes"]', function () {
    var $checkBoxLink, $checkbox;
    $checkbox = $(this);
    $checkBoxLink = $('[data-behaviour="checkbox-link-btn"]');
    if ($checkbox.is(':checked')) {
        $('[data-behaviour="gst-validation-checkboxes"]').prop("checked", true);
        return checkBoxLinkBtnPropChange($checkBoxLink);
    } else {
        $('[data-behaviour="gst-validation-checkboxes"]').prop("checked", false);
        return checkBoxLinkBtnPropChange($checkBoxLink);
    }
});

window.getGstIdFromCheckedBoxes = function () {
    var $checkboxes = $('[data-behaviour="gst-validation-checkboxes"]'), selectedItems = [];
    $checkboxes.each(function () {
        if ($(this).is(':checked')) {
            selectedItems.push($(this).val());
            $($(this).parents('tr')[0]).css('background', 'aliceblue');
        } else {
            $($(this).parents('tr')[0]).css('background', '');
        }
    });
    return selectedItems
};

window.checkBoxLinkBtnPropChange = function (item) {
    if (getGstIdFromCheckedBoxes().length > 0) {
        return item.attr('disabled', false);
    } else {
        return item.attr('disabled', true);
    }
};